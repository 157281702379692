/* Circle */
#circle-gradient {
  background: -webkit-linear-gradient(left top, #1ecbcb, #4a6cc1);
  border-radius: 1000px;
  width: 120px;
  height: 120px;
  padding: 6px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navLink {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.navLink:hover {
  color: #f09443;
}

/* Close button react-bootstrap */
.btn-close {
  position: absolute;
  top: 15px;
  left: 87%;
}

.modal-content {
  border-radius: 12px;
}

.modal-verification-code {
  min-width: 30vw;
}
.modal-verification-code-responsive {
  max-width: 95vw;
  margin: 0 auto;
}
.modal-backdrop {
  background: rgba(0, 46, 42, 1);
}

@media only screen and (min-width: 678px) and (max-width: 992px) {
  .modal-change-password {
    max-width: 40vw !important;
  }
}

.modal-change-password {
  max-width: 26vw;
}
.modal-change-password-responsive {
  max-width: 76vw;
  margin: 0 auto;
}

/* SCROLL HIDDEN BUT CAN SCROLL */
.keep-scrolling {
  background-color: #eee;
  width: 200px;
  height: 100px;
  border: 1px dotted black;
  overflow-y: scroll; /* Add the ability to scroll y axis*/
}

/* Hide scrollbar for Chrome, Safari and Opera */
.keep-scrolling::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.keep-scrolling {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Modal */
.modalSearch,
.myModal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  top: 65px;
  right: 6%;
  /* transform: translate(-50%, -45%); */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modalSearch {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  top: 17%;
  left: 5%;
  width: 90% !important;
  /* transform: translate(-50%, -45%); */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

@media only screen and (max-width: 600px) {
  .myModal {
    top: 13%;
    left: 5%;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .myModal {
    top: 80px;
    left: 5%;
  }
  .modal-cart-res {
    top: 62px !important;
    left: 5% !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .myModal {
    top: 150px;
    left: 5%;
  }
  .modal-cart-res {
    top: 62px !important;
    left: 5% !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .myModal {
    top: 85px;
    left: 50%;
  }
  .modal-cart-res {
    top: 62px !important;
    left: 5% !important;
  }
}

@media only screen and (min-width: 1400px) {
  .modal-cart-res {
    left: 50% !important;
  }
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

#phoneNumberConsultingRoom,
#phoneNumberEmergency,
#phoneNumber {
  border: none;
}

::-webkit-scrollbar {
  width: 5px;
}

.link-login {
  margin-top: 10px;
}

#faq-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#banner-faq {
  width: 499px;
  height: 100px;
  filter: drop-shadow(3.214px 3.83px 3.5px rgba(0, 0, 0, 0.2));
  background-color: #068581;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 50px 175px;
  padding-top: 40px;
}

#banner-faq {
  font-size: 48px;
  color: #ffffff;
  font-weight: 300;
  font-family: 'IBM Plex Sans';
  text-align: center;
}

#question-faq {
  height: 57px;
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  font-family: 'IBM Plex Sans';
}

#accordion-faq {
  width: 809px;
  margin-bottom: 50px;
}

.accordion-button::after {
  margin-top: -50px;
}

.accordion-button {
  height: 60px;
  padding-top: 50px;
  padding-bottom: 0;
  color: var(--bluegreen);
}

.accordion-button:not(.collapsed) {
  color: var(--bluegreen);
  background-color: rgba(0, 127, 126, 0.18);
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
}

.accordion-button:after {
  /* Remember: '%230' = '#' */
  /*
        Whatever is after '%230'
        is the color in hex code
    */
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23007f7e'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

#form-div-faq {
  width: 809px;
  height: 320px;
  filter: drop-shadow(3.214px 3.83px 3.5px rgba(0, 0, 0, 0.2));
  background-color: #c6e6df;
  border: 0px solid #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

#form-div-faq p {
  font-size: 30px;
  color: #007f7e;
  font-weight: 700;
  font-family: 'IBM Plex Sans';
  text-align: center;
  /* border: 1px solid blue; */
  text-align: left;
  width: 660px;
}

#form-faq {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  /* border: 1px solid black; */
}

#inputs-faq {
  /* border: 1px solid red; */
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#sel-tex-btn {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

#name-faq,
#email-faq,
#phone-faq {
  width: 251px;
  height: 40px;
  background-color: #ffffff;
  padding: 10px;
  font-size: 17px;
  color: #00827e;
  border: none;
  margin-bottom: 20px;
}

#msg-faq {
  resize: none;
  width: 360px;
  height: 100px;
  background-color: #ffffff;
  font-size: 17px;
  padding: 10px;
  color: #00827e;
  border: none;
  margin-bottom: 20px;
}

#topic-faq {
  width: 360px;
  height: 40px;
  font-size: 17px;
  color: #00827e;
  font-weight: 700;
  font-family: 'IBM Plex Sans';
}

#name-faq::placeholder,
#email-faq::placeholder,
#phone-faq::placeholder,
#msg-faq::placeholder {
  color: #00827e;
  font-size: 17px;
  /* padding: 10px; */
}

#topic-faq {
  border: 2px solid #068581;
  margin-bottom: 20px;
}

#btn-faq-form:focus {
  outline: none;
  box-shadow: none;
}

textarea {
  resize: none;
}

textarea:focus,
input:focus {
  outline: none;
}

/* Small devices */
@media only screen and (max-width: 600px) {
  #banner-faq {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: 150px;
  }

  #banner-faq p {
    line-height: 60px;
  }

  #accordion-faq {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  #question-faq {
    padding-right: 10px;
  }

  .accordion-button {
    height: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    padding-right: 20px;
    padding-bottom: 10px;
  }

  .accordion-body {
    padding: 1rem 1.25rem;
    padding-bottom: 0px;
  }

  #form-div-faq {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #form-div-faq p {
    width: 100%;
    text-align: center;
  }

  #form-faq {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #inputs-faq,
  #sel-tex-btn {
    width: 105%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #name-faq,
  #email-faq,
  #phone-faq {
    width: 100%;
  }

  #topic-faq,
  #msg-faq {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  #msg-faq {
    height: 150px;
    margin-bottom: 30px;
  }
}

/*Pantallas grandes (desktops de menos de 1200px)*/
@media (max-width: 1199.98px) {
  .modalSearch {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    top: 18%;
    left: -10%;
    width: 100% !important;
    /* transform: translate(-50%, -45%); */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
}

/*Pantallas medianas (tablets de menos de 992px) */
@media (max-width: 991.98px) {
  .modalSearch {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    top: 19.5%;
    left: 5%;
    width: 90% !important;
    /* transform: translate(-50%, -45%); */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
}

/*Pantallas pequeñas (móviles en landscape de menos de 768px)*/
@media (max-width: 767.98px) {
  .modalSearch {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    top: 22.5%;
    left: 5%;
    width: 90% !important;
    /* transform: translate(-50%, -45%); */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
}

/*Pantallas muy pequeñas (móviles en portrait de menos de 576px)*/
@media (max-width: 575.98px) {
  .modalSearch {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000; /* Sit on top */
    top: 23.5%;
    left: 5%;
    width: 90% !important;
    /* transform: translate(-50%, -45%); */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
}
